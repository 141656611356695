import React, { FC } from 'react';
import Main from '../Main/Main';
import Auth from '../Auth/Auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { customAlert } from '../../store/actions/alert';
import Alert from '../../components/UI/Alert/Alert';


const Root: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const showAlert = useAppSelector((state) => state.alert.showAlert);
  const alertMessage = useAppSelector((state) => state.alert.alertMessage);


  const render = () => {
    if (user != null) {
      return <Main />
    } else {
      return <Auth />
    }
  }

  return (
    <div>
      <Alert
        ok={() => dispatch(customAlert(false))}
        message={alertMessage}
        isOpen={showAlert}
      />
      {render()}
    </div>
  );
};

export default Root;

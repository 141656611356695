import { AxiosResponse } from 'axios';
import instance from './instance';

const login = (
  username: string,
  password: string,
  callback: (response: AxiosResponse<any>) => void,
  errorCallback?: (errorMessage: string) => void
): void => {
  const encodedCredentials = window.btoa(username + ':' + password);

  instance.defaults.withCredentials = true;

  instance.post('/auth/login', {}, {
    headers: {
      'Authorization': `Basic ${encodedCredentials}`
    }
  })
    .then(response => callback(response))
    .catch(error => {
      console.log(error)
      let errorMessage = error.message;

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      if (errorCallback) {
        errorCallback(errorMessage);
      }
    });
}

export const activate = (
  username: string,
  password: string,
  activationCode: string,
  callback: (response: AxiosResponse<any>) => void,
  errorCallback?: (errorMessage: string) => void
): void => {
  const encodedCredentials = window.btoa(username + ':' + password);

  instance.defaults.withCredentials = true;

  instance.post('/auth/login', {}, {
    headers: {
      'Authorization': `Basic ${encodedCredentials}`,
      'x-activation-code': activationCode
    }
  })
    .then(response => callback(response))
    .catch(error => {
      console.log(error)
      let errorMessage = error.message;

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      if (errorCallback) {
        errorCallback(errorMessage);
      }
    });
}

export default login;

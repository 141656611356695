import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './WeighMethod.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface WeighMethodsProps { };

const WeighMethods: React.FC<WeighMethodsProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [weighMethods, setWeighMethod] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewWeighMethod = (weighMethod: { alias: string }) => {
    post(
      '/weigh-method',
      { alias: weighMethod.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getWeighMethods();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteWeighMethod = (weighMethodId: string) => {
    del(
      `/weigh-method/${weighMethodId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getWeighMethods();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editWeighMethod = (weighMethodId: string, weighMethodName: string) => {
    patch(
      `/weigh-method/${weighMethodId}`,
      { alias: weighMethodName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getWeighMethods();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getWeighMethods = useCallback(
    () => {
      get(
        '/weigh-method',
        {},
        (res) => {
          if (isMounted) {
            setWeighMethod(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getWeighMethods();

    return () => {
      isMounted.current = false;
    }
  }, [getWeighMethods])

  return (
    <>
      <EntityBox
        entityName='weigh method'
        header='Weigh Methods'
        isLoading={isLoading}
        entities={weighMethods}
        isBroken={isBroken}
        delete={deleteWeighMethod}
        edit={editWeighMethod}
        createNewEntity={createNewWeighMethod}
      />
    </>

  );
};

export default WeighMethods;
import React from 'react';
import classes from './Loader.module.scss';

interface LoaderProps {
  size?: 'tiny' | 'small' | 'medium' | 'large';
}

const Loader: React.FC<LoaderProps> = ({ size = 'medium' }) => {
  return (
    <div className={`${classes.loader} ${classes[size]}`}></div>
  );
};

export default Loader;

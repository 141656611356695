import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Accounts.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface AccountsProps { };

const Accounts: React.FC<AccountsProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewAccount = (account: { alias: string }) => {
    post(
      '/account',
      { alias: account.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getAccounts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteAccount = (accountId: string) => {
    del(
      `/account/${accountId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getAccounts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editAccount = (accountId: string, accountName: string) => {
    patch(
      `/account/${accountId}`,
      { alias: accountName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getAccounts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getAccounts = useCallback(
    () => {
      get(
        '/account',
        {},
        (res) => {
          if (isMounted) {
            setAccounts(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getAccounts();

    return () => {
      isMounted.current = false;
    }
  }, [getAccounts])

  return (
    <>
      <EntityBox
        entityName='account'
        header='Accounts'
        isLoading={isLoading}
        entities={accounts}
        isBroken={isBroken}
        delete={deleteAccount}
        edit={editAccount}
        createNewEntity={createNewAccount}
      />
    </>

  );
};

export default Accounts;
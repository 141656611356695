import React, { FC, MouseEvent } from 'react';
import classes from './Day.module.scss';

interface DayProps {
  day?: number;
  isDummy?: boolean;
  setDay: (day: number) => void;
  date: Date;
  isValid: boolean;
}

const Day: FC<DayProps> = (props) => {
  const setDay = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!props.isDummy && props.day != null) {
      props.setDay(props.day);
    }
  };

  const determineClasses = (): string => {
    let classList = [classes.day];

    if (props.isDummy) {
      classList.push(classes['dummy-day']);
    } else {
      classList.push(classes['real-day']);

      if (props.date.getDate() === props.day) {
        if (props.isValid) {
          classList.push(classes['selected']);
        } else {
          classList.push(classes['invalid']);
        }
      }
    }

    return classList.join(' ');
  };

  return (
    <div className={determineClasses()} onClick={setDay}>
      {props.day}
    </div>
  );
};

export default Day;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Users.module.scss';
import get from '../../../services/axios/get';
import del from '../../../services/axios/delete';
import patch from '../../../services/axios/patch';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import post from '../../../services/axios/post';
import { Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { CgMenu } from 'react-icons/cg';
import Loader from '../../UI/Loader/Loader';

interface UsersProps { };

interface User {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  activationCode: string
  userType: string
}

const Users: React.FC<UsersProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [users, setUser] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewUser = (user: { alias: string, phoneNumber?: string }) => {
    post(
      '/user',
      { alias: user.alias, phoneNumber: user.phoneNumber },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteUser = (userId: string) => {
    del(
      `/user/${userId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editUser = (userId: string, userName: string, phoneNumber?: string) => {
    patch(
      `/user/${userId}`,
      { alias: userName, phoneNumber },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getUsers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getUsers = useCallback(
    () => {
      get(
        '/user',
        {},
        (res) => {
          if (isMounted) {
            setUser(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  const renderUsers = () => {
    return users.map((user: User, index: number) => {
      const shadeClass = index % 2 === 0 ? 'even' : 'odd';

      return <div className={[classes['user'], classes[shadeClass]].join(' ')}>
        <div>{user.firstName} {user.lastName}</div>
        <div>{user.userType}</div>
        <div>{user.email}</div>
        <div>{user.phoneNumber}</div>
        <div>{user.activationCode}</div>

        <div className={classes['menu']}>
          <Dropdown isOpen={false} toggle={() => { }} direction={'down'}>
            <DropdownToggle><CgMenu size={'16'} /></DropdownToggle>
            <DropdownMenu >

            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    })
  }

  useEffect(() => {
    getUsers();

    return () => {
      isMounted.current = false;
    }
  }, [getUsers])

  return (
    <Container>
      <Row>
        <Col>
          <div className={classes['users-box']}>
            <div className={classes['headers']}>
              <div>Name</div>
              <div>User Type</div>
              <div>Email</div>
              <div>Phone</div>
              <div>Activation Code</div>
            </div>

            {isLoading ? <div className={classes['loader-container']}>
              <Loader size='large' />
            </div> : renderUsers()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
import { Ref, useEffect, useRef, useState } from 'react';
import classes from './Dropdown.module.scss';
import Loader from '../../Loader/Loader';

interface DropdownProps {
  defaultValue: string;
  value: string;
  options: {
    value: string | object;
    displayValue: string;
    isPlaceholder?: boolean;
  }[];
  onChange: (value: string | object) => void;
  allowEmpty?: boolean;
  inputStyle?: string;
  isLoading: boolean;
  disabled?: boolean;
}

const Dropdown = (props: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownUpwards, setIsDropdownUpwards] = useState(false);
  const dropdownRef: Ref<any> = useRef(null);
  const inputClasses = [classes[props.inputStyle || ''], classes.dropdown].join(' ');

  const sortedOptions = [...props.options].sort((a: any, b: any) => {
    const lowerA = a.displayValue.toLowerCase();
    const lowerB = b.displayValue.toLowerCase();
    const aStartsWithStar = lowerA.startsWith('*');
    const bStartsWithStar = lowerB.startsWith('*');

    if (aStartsWithStar && !bStartsWithStar) {
      return 1;
    }
    if (!aStartsWithStar && bStartsWithStar) {
      return -1;
    }

    if (lowerA < lowerB) {
      return -1;
    }
    if (lowerA > lowerB) {
      return 1;
    }
    return 0;
  });

  const filteredOptions = sortedOptions.filter(option => {
    return option.displayValue.toLowerCase().includes(searchTerm.toLowerCase())
  }
  );

  const handleDropdown = () => {
    if (props.disabled === true) {
      return
    }

    setIsOpen(!isOpen);

    if (!isOpen) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      const dropdownHeight = 200

      if (spaceBelow < dropdownHeight) {
        setIsDropdownUpwards(true);
      } else {
        setIsDropdownUpwards(false);
      }
    }
  };

  const handleSelect = (value: string | object, displayValue: string) => {
    setSelectedValue(displayValue);
    props.onChange(value);
    setSearchTerm('');
    setIsOpen(false);
  };

  const renderDisplayedValue = (value: string) => {
    return value !== '' ? <div key={value}>{value}</div> : <div>---</div>;
  }


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchTerm('');
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, props.value]);
  return (
    <div className={[inputClasses].join(' ')} ref={dropdownRef}>
      {isOpen ? (
        <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={classes.searchInput}
            autoFocus
          />
          <div>
            <ul className={[classes.dropdownList, isDropdownUpwards ? classes.upward : classes.downward].join(' ')}>
              {props.isLoading ? (
                <div className={classes['loader-container']}>
                  <Loader size='medium' />
                </div>
              ) : (
                <>
                  {props.allowEmpty === true ? (
                    <li
                      key='0'
                      className={classes.dropdownItem}
                      onClick={() => handleSelect('', '')}
                    >
                      ---
                    </li>
                  ) : null}

                  {filteredOptions.map((option, index) => (
                    <li
                      key={option.value + index.toString()}
                      className={classes.dropdownItem}
                      onClick={() => handleSelect(option.value, option.displayValue)}
                    >
                      {option.displayValue}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        </div>
      ) : (<div className={classes.dropdownSelected} onClick={handleDropdown}>
        {renderDisplayedValue(selectedValue)}
      </div>)}
    </div>
  );
};

export default Dropdown;

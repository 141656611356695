import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoadCounts from './LoadCounts/LoadCounts';

interface HomeProps { };

const Home: React.FC<HomeProps> = (props) => {
  return (
    <Container>
      <Row>
        <Col xs='12' lg='6'>
          <LoadCounts />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
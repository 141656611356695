import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Root from './containers/Root/Root';
import { wakeLambdas } from './helpers/wakeLambdas';

function App() {
  useEffect(() => {
    wakeLambdas(1);
  })

  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;

import React, { ReactNode } from 'react';
import classes from './Input.module.scss';
import Dropdown from './Dropdown/Dropdown';

interface InputProps {
  id: string;
  inputStyle?: string;
  invalid?: boolean;
  shouldValidate?: boolean;
  touched?: boolean;
  elementType: 'input' | 'textarea' | 'select' | string;
  elementConfig: {
    options?: {
      value: string | object;
      displayValue: string;
      isPlaceholder?: boolean;
    }[];
    [key: string]: any;
  };
  value: string;
  change: (event: any) => void;
  label?: string;
  labelHidden?: boolean;
  allowEmpty?: boolean;
  confirm?: () => void;
  autoFocus?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const formatCurrency = (value: string) => {
  const newValue = value.replace(/^R\s?/, '');
  const decimalMatch = newValue.match(/^\d*\.?\d{0,2}/);
  const decimalValue = decimalMatch ? decimalMatch[0] : '';

  return `R ${decimalValue}`
};

const Input: React.FC<InputProps> = (props) => {
  let inputElement: ReactNode = null;
  const inputClasses = [classes[props.inputStyle || '']];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(classes.invalid);
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          id={props.id}
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.change}
          autoFocus={props.autoFocus === true ? true : false}
          onBlur={props.confirm != null ? props.confirm : () => { }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && props.confirm != null) {
              props.confirm();
            }
          }}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          id={props.id}
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.change}
        />
      );
      break;
    case 'select':
      inputElement = (
        <Dropdown
          options={props.elementConfig.options!}
          onChange={(value: string | object) => props.change({ target: { value, id: props.id } })}
          defaultValue={props.value}
          value={props.value}
          allowEmpty={props.allowEmpty}
          inputStyle={props.inputStyle}
          isLoading={props.isLoading ?? false}
          disabled={props.disabled}
        />
      );
      break;
    case 'time':
      inputElement = (
        <input
          type="time"
          id={props.id}
          className={`${inputClasses.join(' ')} ${classes.time}`}
          {...props.elementConfig}
          value={props.value}
          onChange={props.change}
        />
      );
      break;
    case 'currency':
      inputElement = (
        <input
          type='text'
          id={props.id}
          className={`${inputClasses.join(' ')}`}
          {...props.elementConfig}
          value={props.value.length > 0 ? formatCurrency(props.value) : props.value}
          onChange={props.change}
          onBlur={props.confirm != null ? props.confirm : () => { }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && props.confirm != null) {
              props.confirm();
            }
          }}
        />
      );
      break;
    case 'checkbox':
      inputElement = (
        <div className={classes.checkboxContainer}>
          <input
            type="checkbox"
            id={props.id}
            className={[...inputClasses, classes.checkbox].join(' ')}
            checked={props.value.toLocaleLowerCase() === 'yes'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.change({ target: { value: event.target.checked } })
            }
          />
          <label
            className={classes.checkboxLabel}
            htmlFor={props.id}
          >
            {props.label}
          </label>
        </div>
      );
      break;
    default:
      inputElement = (
        <input
          id={props.id}
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.change}
        />
      );
  }

  return (
    <div className={classes.input}>
      <label
        className={classes.label}
        htmlFor={props.id}
        hidden={props.labelHidden ? true : false}
      >
        {props.label}
      </label>
      {inputElement}
    </div>
  );
};

export default Input;

import { AxiosResponse } from 'axios';
import axiosLogin, { activate as activateService } from '../../services/axios/login';
import {
  SET_USER
} from '../constants/auth';
import { customAlert } from './alert';
import { removeFromLocalStorage } from '../../helpers/deleteFromLocalStorage';
import { saveToLocalStorage } from '../../helpers/saveToLocalStorage';

export const login = (
  username: string,
  password: string,
  callback: (success: boolean, response?: AxiosResponse) => void) => {
  return (dispatch: Function) => {
    axiosLogin(username, password, (response) => {
      saveToLocalStorage('token', response.data.token)

      callback(true, response);
      dispatch({
        type: SET_USER,
        user: response.data
      });
    }, (error) => {
      callback(false);
      dispatch(customAlert(true, error))
    });
  };
};

export const activate = (
  username: string,
  password: string,
  activationCode: string,
  callback: (success: boolean, response?: AxiosResponse) => void) => {
  return (dispatch: Function) => {
    activateService(username, password, activationCode, (response) => {
      saveToLocalStorage('token', response.data.token)

      callback(true, response);
      dispatch({
        type: SET_USER,
        user: response.data
      });
    }, (error) => {
      callback(false);
      dispatch(customAlert(true, error))
    });
  };
};

export const logout = () => {
  return (dispatch: Function) => {
    removeFromLocalStorage('token');

    dispatch({
      type: SET_USER,
      user: null
    });
  };
};

import get from '../services/axios/get';

export const wakeLambdas = (concurrency: number) => {
  for (let i = 0; i < concurrency; i++) {
    get(
      '/ping',
      {},
      (res) => { },
      () => { });
  }
}
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './TempTaleSupplier.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface TempTaleSuppliersProps { };

const TempTaleSuppliers: React.FC<TempTaleSuppliersProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [tempTaleSuppliers, setTempTaleSupplier] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewTempTaleSupplier = (tempTaleSupplier: { alias: string }) => {
    post(
      '/temp-tale-supplier',
      { alias: tempTaleSupplier.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleSuppliers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteTempTaleSupplier = (tempTaleSupplierId: string) => {
    del(
      `/temp-tale-supplier/${tempTaleSupplierId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleSuppliers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editTempTaleSupplier = (tempTaleSupplierId: string, tempTaleSupplierName: string) => {
    patch(
      `/temp-tale-supplier/${tempTaleSupplierId}`,
      { alias: tempTaleSupplierName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleSuppliers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getTempTaleSuppliers = useCallback(
    () => {
      get(
        '/temp-tale-supplier',
        {},
        (res) => {
          if (isMounted) {
            setTempTaleSupplier(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getTempTaleSuppliers();

    return () => {
      isMounted.current = false;
    }
  }, [getTempTaleSuppliers])

  return (
    <>
      <EntityBox
        entityName='temp-tale supplier'
        header='Temptale Suppliers'
        isLoading={isLoading}
        entities={tempTaleSuppliers}
        isBroken={isBroken}
        delete={deleteTempTaleSupplier}
        edit={editTempTaleSupplier}
        createNewEntity={createNewTempTaleSupplier}
      />
    </>

  );
};

export default TempTaleSuppliers;
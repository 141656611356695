import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Vent.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface VentsProps { };

const Vents: React.FC<VentsProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [vents, setVent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewVent = (vent: { alias: string }) => {
    post(
      '/vent-type',
      { alias: vent.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVents();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteVent = (ventId: string) => {
    del(
      `/vent-type/${ventId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVents();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editVent = (ventId: string, ventName: string) => {
    patch(
      `/vent-type/${ventId}`,
      { alias: ventName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVents();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getVents = useCallback(
    () => {
      get(
        '/vent-type',
        {},
        (res) => {
          if (isMounted) {
            setVent(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getVents();

    return () => {
      isMounted.current = false;
    }
  }, [getVents])

  return (
    <>
      <EntityBox
        entityName='vent'
        header='Vents'
        isLoading={isLoading}
        entities={vents}
        isBroken={isBroken}
        delete={deleteVent}
        edit={editVent}
        createNewEntity={createNewVent}
      />
    </>

  );
};

export default Vents;
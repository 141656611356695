import { Dispatch } from 'redux';
import { SET_SHOW_ALERT, SET_ALERT_MESSAGE } from '../constants/alert';

interface SetShowAlertAction {
  type: typeof SET_SHOW_ALERT;
  showAlert: boolean;
}

interface SetAlertMessageAction {
  type: typeof SET_ALERT_MESSAGE;
  alertMessage: string;
}

type AlertActionTypes = SetShowAlertAction | SetAlertMessageAction;

export const customAlert = (shouldShow: boolean, message?: string) => {
  return (dispatch: Dispatch<AlertActionTypes>) => {
    dispatch({
      type: SET_SHOW_ALERT,
      showAlert: shouldShow
    });

    if (message != null) {
      dispatch({
        type: SET_ALERT_MESSAGE,
        alertMessage: message
      });
    }
  };
};

import React, { useState, useCallback, ReactNode, CSSProperties } from 'react';

interface DragAndDropUploadProps {
    onFileUpload: (file: File) => void;
    children?: ReactNode;
    boxed?: boolean;
}

const DragAndDropUpload: React.FC<DragAndDropUploadProps> = ({ onFileUpload, children, boxed }) => {
    const [isDragOver, setIsDragOver] = useState(false);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length) {
            const file = e.dataTransfer.files[0];
            onFileUpload(file);
        }
    }, [onFileUpload]);

    const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];
            onFileUpload(file);
        }
    }, [onFileUpload]);

    let style: CSSProperties = {
        border: isDragOver ? '2px dashed var(--theme-green)' : '2px solid transparent',
        borderRadius: '5px',
        margin: '-2px'
    }

    if (boxed === true) {
        style = {
            border: isDragOver ? '2px solid var(--theme-green)' : '2px dashed var(--theme-green)',
            padding: 20,
            textAlign: 'center',
            position: 'relative',
            minWidth: '300px',
            width: '100%',
            display: 'flex',
            color: 'var(--theme-grey)',
            borderRadius: '5px',
            justifyContent: 'center',
        }
    }

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={style}
        >
            <input
                type="file"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
            />

            {children !== undefined ? (
                children
            ) : (
                <div>Drag files here...</div>
            )}
        </div>
    );
};

export default DragAndDropUpload;

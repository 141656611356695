import React, { FC, ReactNode } from 'react';
import Button from '../Button/Button';
import classes from './Alert.module.scss';
import Axl from '../../../containers/Axl';
import Modal from '../../../containers/Modal/Modal';

interface AlertProps {
  isOpen: boolean;
  message: string | ReactNode;
  isConfirm?: boolean;
  confirm?: (confirmValue: boolean) => void;
  ok?: () => void;
}

const Alert: FC<AlertProps> = (props) => {
  if (!props.isOpen) return null;

  return (
    <Modal className={classes.modal} zIndex={9999}>
      <div>
        <span>{props.message}</span>
      </div>

      <div className={classes.footer}>
        {props.isConfirm ? (
          <Axl>
            <Button
              type='button'
              buttonStyle='main'
              text='Cancel'
              click={() => props.confirm && props.confirm(false)}
            />
            <Button
              type='button'
              buttonStyle='main'
              text='Yes'
              click={() => props.confirm && props.confirm(true)}
            />
          </Axl>
        ) : (
          <Button
            type='button'
            buttonStyle='main'
            text='Ok'
            click={props.ok}
          />
        )}
      </div>
    </Modal>
  );
};

export default Alert;

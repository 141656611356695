import React, { useCallback, useEffect, useRef, useState } from 'react';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface ContainerProps { };

const Containers: React.FC<ContainerProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [containers, setContainers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewContainer = (container: { alias: string }) => {
    post(
      '/container',
      { alias: container.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getContainers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteContainer = (containerId: string) => {
    del(
      `/container/${containerId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getContainers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editContainer = (containerId: string, containerName: string) => {
    patch(
      `/container/${containerId}`,
      { alias: containerName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getContainers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getContainers = useCallback(
    () => {
      get(
        '/container',
        {},
        (res) => {
          if (isMounted) {
            setContainers(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getContainers();

    return () => {
      isMounted.current = false;
    }
  }, [getContainers])

  return (
    <>
      <EntityBox
        entityName='containers'
        header='Containers'
        isLoading={isLoading}
        entities={containers}
        isBroken={isBroken}
        delete={deleteContainer}
        edit={editContainer}
        createNewEntity={createNewContainer}
      />
    </>

  );
};

export default Containers;
import React from 'react';
import classes from './AdminMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const AdminMenu: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className={[classes['admin-menu']].join(' ')}>
            <NavLink to='loads'
              className={({ isActive, isPending }) => isPending ?
                classes.pending : isActive ? classes.active : ""}
            >Loads</NavLink>

            <NavLink to='prices'
              className={({ isActive, isPending }) => isPending ?
                classes.pending : isActive ? classes.active : ""}
            >Price Lists</NavLink>

            <NavLink to='users'
              className={({ isActive, isPending }) => isPending ?
                classes.pending : isActive ? classes.active : ""}
            >Users</NavLink>
          </div>
        </Col>
      </Row>
    </Container>

  );
};

export default AdminMenu;

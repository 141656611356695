import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './TempCodes.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface TempCodeProps { };

const TempCodes: React.FC<TempCodeProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [tempCodes, setTempCode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewTempCode = (tempCode: { alias: string }) => {
    post(
      '/temp-code',
      { alias: tempCode.alias, isDischarge: false },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempCodes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteTempCode = (tempCodeId: string) => {
    del(
      `/temp-code/${tempCodeId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempCodes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editTempCode = (tempCodeId: string, tempCodeName: string) => {
    patch(
      `/temp-code/${tempCodeId}`,
      { alias: tempCodeName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempCodes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getTempCodes = useCallback(
    () => {
      get(
        '/temp-code',
        { isDischarge: false },
        (res) => {
          if (isMounted) {
            setTempCode(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getTempCodes();

    return () => {
      isMounted.current = false;
    }
  }, [getTempCodes])

  return (
    <>
      <EntityBox
        entityName='temp code'
        header='Temp Codes'
        isLoading={isLoading}
        entities={tempCodes}
        isBroken={isBroken}
        delete={deleteTempCode}
        edit={editTempCode}
        createNewEntity={createNewTempCode}
      />
    </>

  );
};

export default TempCodes;
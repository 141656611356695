import React, { useState } from 'react';
import Load, { Charge } from '../../../models/load';
import Input from '../../UI/Input/Input';
import { Form, FormItem } from '../EditLoad/EditLoad';
import checkValidity from '../../../helpers/checkValidity';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button/Button';
import classes from './Invoicing.module.scss'

type FormKeys = 'containerNumber' | 'invoiceNumber'

interface InvoicingProps {
  load: Load
  putLoad: (load: Load, loadId: string, onError: () => void) => void;
  close: () => void
};

const Invoicing: React.FC<InvoicingProps> = (props) => {
  const initialForm: Form = {
    containerNumber: { value: props.load.containerNumber ?? '', isValid: false, isTouched: false },
    invoiceNumber: { value: props.load.invoiceNumber ?? '', isValid: false, isTouched: false },
    clientPrice: { value: props.load.clientPrice ?? '', isValid: false, isTouched: false },
    subPrice: { value: props.load.subPrice ?? '', isValid: false, isTouched: false },
  }

  const [formValues, setFormValues] = useState(initialForm)
  const [isLoading, setIsLoading] = useState(false);

  const putLoad = () => {
    const load: Load | null = parseForm()

    if (load != null) {
      setIsLoading(true);

      props.putLoad(load, props.load.id ?? '', () => setIsLoading(false));
    }
  }

  const updateForm = (value: string, identifier: string) => {
    setFormValues(prevState => {
      let newForm: Form = { ...prevState };
      let newItem: FormItem = { ...newForm[identifier] }

      if (typeof value === 'string' && value.length >= 1) {
        newItem.value = value.trim();
      } else {
        newItem.value = value;
      }

      newItem.isTouched = true;

      if (typeof value === 'string') {
        newItem.isValid = checkValidity(value, {
          required: true,
          minLength: 2,
          maxLength: 40,
          isEmail: false
        });
      } else {
        newItem.isValid = true
      }

      newForm[identifier] = newItem;

      return newForm;
    })
  };

  const parseForm = (): Load | null => {
    const form: Partial<Load> = {};

    Object.keys(formValues).forEach((key) => {
      const loadKey = key as FormKeys;
      let value = formValues[loadKey].value;

      if ((typeof value) === 'string') {
        value = value.trim();
      }

      form[loadKey] = value;
    });

    const updatedLoad = { ...props.load, ...form };

    return updatedLoad as Load;
  };

  return (
    <div>
      <h6>Load Summary</h6>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          putLoad();
        }}
      >
        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Date:</div>
            <div className={classes['value']}>{(props.load.loadDate ?? '').split('T')[0]}</div>
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Client:</div>
            <div className={classes['value']}>{(props.load.client ?? '')}</div>
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Driver:</div>
            <div className={classes['value']}>{(props.load.driver?.alias ?? '')}</div>
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Load Point 1:</div>
            <div className={classes['value']}>{(props.load.loadPoint1 ?? '')}</div>
          </div>

          <div className={classes['info-block']}>
            <div className={classes['label']}>Load Point 2:</div>
            <div className={classes['value']}>{(props.load.loadPoint2 ?? '')}</div>
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Booking Ref:</div>
            <div className={classes['value']}>{(props.load.bookingRef ?? '')}</div>
          </div>

          <div className={classes['info-block']}>
            <div className={classes['label']}>Client Ref:</div>
            <div className={classes['value']}>{(props.load.clientRef ?? '')}</div>
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Container Number:</div>

            <Input
              elementType={'text'}
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={formValues.containerNumber.value}
              invalid={!formValues.containerNumber.isValid}
              shouldValidate
              touched={formValues.containerNumber.isTouched}
              change={(e) => updateForm(e.target.value, 'containerNumber')}
              id='containerNumber-input'
              label='Container No.'
              inputStyle='main'
              labelHidden
            />
          </div>

          <div className={classes['info-block']}>
            <div className={classes['label']}>Invoice Number:</div>

            <Input
              elementType={'text'}
              elementConfig={{
                type: 'text',
                placeholder: '',
              }}
              value={formValues.invoiceNumber.value}
              invalid={!formValues.invoiceNumber.isValid}
              shouldValidate
              touched={formValues.invoiceNumber.isTouched}
              change={(e) => updateForm(e.target.value, 'invoiceNumber')}
              id='invoiceNumber-input'
              label='Invoice No.'
              inputStyle='main'
              labelHidden
            />
          </div>
        </div>

        <div className={classes['info-line']}>
          <div className={classes['info-block']}>
            <div className={classes['label']}>Additional Charges:</div>
          </div>
        </div>

        {JSON.parse(props.load.charges ?? '[]').map((charge: Charge) => {
          return <div className={classes['charge']}>
            <div>{charge.description}</div>
            <div>{charge.quantity}</div>
            <div className={classes['charge-amounts']}>
              <div>R {charge.amount}</div>
              <div>R {(parseInt(charge.amount) * charge.quantity).toFixed(2)}</div>
            </div>
          </div>
        })}

        <div className={classes['charge-total']}>
          <div className={classes['label']}>Total: </div>
          <div className={classes['value']}>R {JSON.parse(props.load.charges ?? '[]').reduce(
            (acc: number, charge: Charge) => {
              return acc + (parseInt(charge.amount) * charge.quantity)
            }, 0).toFixed(2)}</div>
        </div>

        <div className={[classes.buttons, 'pt-3'].join(' ')}>
          <div className='pointer no-select' onClick={props.close}>Cancel</div>

          {isLoading ? <Loader
            size='small'
          /> : (
            <Button
              type='submit'
              buttonStyle='main'
              text='Confirm'
            />
          )}
        </div>

      </form>
    </div>
  );
};

export default Invoicing;
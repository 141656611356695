import React, { useState } from 'react';
import classes from './NewEntity.module.scss';
import Modal from '../../../../../containers/Modal/Modal';
import checkValidity from '../../../../../helpers/checkValidity';
import Input from '../../../../UI/Input/Input';
import Button from '../../../../UI/Button/Button';

export interface Entity {
  alias: string;
  phoneNumber?: string
}

interface NewEntityProps {
  close: () => void;
  newEntity: Entity;
  createNewEntity: (entity: Entity) => void;
  setNewEntity: (entity: Entity) => void;
  entityName: string;
};

const NewEntity: React.FC<NewEntityProps> = (props) => {
  const [isValid, setIsValid] = useState(true);

  return (
    <Modal close={props.close}>
      <h6>New {props.entityName.charAt(0).toUpperCase() + props.entityName.slice(1)}</h6>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (isValid) {
            props.createNewEntity(props.newEntity);
            props.close();
          }
        }}
      >
        <div className={[classes.inputs, 'py-3'].join(' ')}>
          <Input
            elementType={'input'}
            elementConfig={{
              type: 'text',
              placeholder: 'Name'
            }}
            value={props.newEntity.alias}
            change={(e: any) => {
              setIsValid(checkValidity(
                e.target.value, {
                required: true,
                minLength: 3,
                maxLength: 40
              }
              ))
              props.setNewEntity({
                alias: e.target.value,
                phoneNumber: props.newEntity.phoneNumber
              });
            }}
            id={'new-entity'}
            labelHidden
            inputStyle='edit'
            touched={true}
            shouldValidate={true}
            invalid={!isValid}
          />
        </div>

        {props.entityName === 'driver' ? (
          <div className={[classes.inputs, 'pb-3'].join(' ')}>
            <Input
              elementType={'input'}
              elementConfig={{
                type: 'text',
                placeholder: 'Phone Number'
              }}
              value={props.newEntity.phoneNumber ?? ''}
              change={(e: any) => {
                setIsValid(checkValidity(
                  e.target.value, {
                  required: true,
                  minLength: 3,
                  maxLength: 40
                }
                ))
                props.setNewEntity({
                  alias: props.newEntity.alias,
                  phoneNumber: e.target.value,
                });
              }}
              id={'new-entity'}
              labelHidden
              inputStyle='edit'
              touched={true}
              shouldValidate={true}
              invalid={!isValid}
            />
          </div>
        ) : null}

        <div className={classes.buttons}>
          <div className='pointer no-select' onClick={props.close}>Cancel</div>

          <Button
            type='submit'
            buttonStyle='main'
            text='Submit'
          />
        </div>
      </form>
    </Modal>
  );
};

export default NewEntity;
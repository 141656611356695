import React from 'react';
import classes from './Toggle.module.scss';

interface ToggleProps {
  isToggled: boolean;
  toggle: () => void;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  return (
    <div
      className={`${classes.toggle} ${props.isToggled ? classes.on : classes.off}`}
      onClick={() => props.toggle()}></div>
  );
};

export default Toggle;

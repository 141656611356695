import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './ports.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface LoadingPortProps { };

const LoadingPorts: React.FC<LoadingPortProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [loadingPorts, setLoadingPort] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewLoadingPort = (loadingPort: { alias: string }) => {
    post(
      '/port',
      { alias: loadingPort.alias, isDischarge: false },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadingPorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteLoadingPort = (loadingPortId: string) => {
    del(
      `/port/${loadingPortId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadingPorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editLoadingPort = (loadingPortId: string, loadingPortName: string) => {
    patch(
      `/port/${loadingPortId}`,
      { alias: loadingPortName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getLoadingPorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getLoadingPorts = useCallback(
    () => {
      get(
        '/port',
        { isDischarge: false },
        (res) => {
          if (isMounted) {
            setLoadingPort(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getLoadingPorts();

    return () => {
      isMounted.current = false;
    }
  }, [getLoadingPorts])

  return (
    <>
      <EntityBox
        entityName='loading port'
        header='Loading Ports'
        isLoading={isLoading}
        entities={loadingPorts}
        isBroken={isBroken}
        delete={deleteLoadingPort}
        edit={editLoadingPort}
        createNewEntity={createNewLoadingPort}
      />
    </>

  );
};

export default LoadingPorts;
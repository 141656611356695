import React, { FC } from 'react';
import Login from '../../components/Login/Login';
import LogoBackdrop from '../../components/LogoBackdrop/LogoBackdrop';
import { Navigate, Route, Routes } from 'react-router-dom';
import ActivateAccount from '../../components/ActivateAccount/ActivateAccount';


const Auth: FC = () => {
  return (
    <LogoBackdrop >
      <Routes>
        <Route
            path='/activate'
            element={<ActivateAccount />}
          />

        <Route
          path='/'
          element={<Login />}
        />

        <Route
          path='/*'
          element={<Navigate to='/' />}
        />
      </Routes>
    </LogoBackdrop>
  );
};

export default Auth;

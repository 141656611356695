import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import classes from './Main.module.scss';
import Header from '../../components/Header/Header';
import Loads from '../../components/Loads/Loads';
import Admin from '../../components/Admin/Admin';
import { wakeLambdas } from '../../helpers/wakeLambdas';
import Home from '../../components/Home/Home';
import { useAppSelector } from '../../store/hooks';

const Main: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)

  // TODO: This seems to be running more than it should
  useEffect(() => {
    wakeLambdas(15);
  })

  return (
    <div className={classes.main}>
      <Header />

      <div className={classes.content}>
        <Routes>
          <Route
            path='/'
            element={<Navigate to={
              user?.userType !== 'driver' ? '/home' : '/loads/active'
            } />}
          />

          <Route
            path='/home'
            element={<Home />}
          />

          <Route
            path='/loads/*'
            element={<Loads />}
          />

          <Route
            path='/admin/*'
            element={<Admin />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Main;

import { SET_SHOW_ALERT, SET_ALERT_MESSAGE } from '../constants/alert';

interface AlertState {
  showAlert: boolean;
  alertMessage: string;
}

interface SetShowAlertAction {
  type: typeof SET_SHOW_ALERT;
  showAlert: boolean;
}

interface SetAlertMessageAction {
  type: typeof SET_ALERT_MESSAGE;
  alertMessage: string;
}

type AlertActionTypes = SetShowAlertAction | SetAlertMessageAction;

const initiateState: AlertState = {
  showAlert: false,
  alertMessage: ''
};

const alert = (state: AlertState = initiateState, action: AlertActionTypes): AlertState => {
  if (action.type === SET_SHOW_ALERT) {
    return {
      ...state,
      showAlert: action.showAlert
    };
  }

  if (action.type === SET_ALERT_MESSAGE) {
    return {
      ...state,
      alertMessage: action.alertMessage
    };
  }

  return state;
};

export default alert;

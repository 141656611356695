import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './EmptyCommodityt.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface CommodityProps { };

const Commodities: React.FC<CommodityProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [commodities, setCommodities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewCommodity = (commodity: { alias: string }) => {
    post(
      '/commodity',
      { alias: commodity.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getCommodities();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteCommodity = (commodityId: string) => {
    del(
      `/commodity/${commodityId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getCommodities();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editCommodity = (commodityId: string, commodityName: string) => {
    patch(
      `/commodity/${commodityId}`,
      { alias: commodityName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getCommodities();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getCommodities = useCallback(
    () => {
      get(
        '/commodity',
        {},
        (res) => {
          if (isMounted) {
            setCommodities(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getCommodities();

    return () => {
      isMounted.current = false;
    }
  }, [getCommodities])

  return (
    <>
      <EntityBox
        entityName='commodity'
        header='Commodities'
        isLoading={isLoading}
        entities={commodities}
        isBroken={isBroken}
        delete={deleteCommodity}
        edit={editCommodity}
        createNewEntity={createNewCommodity}
      />
    </>

  );
};

export default Commodities;
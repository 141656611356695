import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './TempTaleType.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface TempTaleTypesProps { };

const TempTaleTypes: React.FC<TempTaleTypesProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [tempTaleTypes, setTempTaleType] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewTempTaleType = (tempTaleType: { alias: string }) => {
    post(
      '/temp-tale-type',
      { alias: tempTaleType.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleTypes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteTempTaleType = (tempTaleTypeId: string) => {
    del(
      `/temp-tale-type/${tempTaleTypeId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleTypes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editTempTaleType = (tempTaleTypeId: string, tempTaleTypeName: string) => {
    patch(
      `/temp-tale-type/${tempTaleTypeId}`,
      { alias: tempTaleTypeName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempTaleTypes();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getTempTaleTypes = useCallback(
    () => {
      get(
        '/temp-tale-type',
        {},
        (res) => {
          if (isMounted) {
            setTempTaleType(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getTempTaleTypes();

    return () => {
      isMounted.current = false;
    }
  }, [getTempTaleTypes])

  return (
    <>
      <EntityBox
        entityName='temp-tale type'
        header='Temptale Types'
        isLoading={isLoading}
        entities={tempTaleTypes}
        isBroken={isBroken}
        delete={deleteTempTaleType}
        edit={editTempTaleType}
        createNewEntity={createNewTempTaleType}
      />
    </>

  );
};

export default TempTaleTypes;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './TempSettings.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface TempSettingProps { };

const TempSettings: React.FC<TempSettingProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [tempSettings, setTempSetting] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewTempSetting = (tempSetting: { alias: string }) => {
    post(
      '/temp-setting',
      { alias: tempSetting.alias, isDischarge: false },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempSettings();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteTempSetting = (tempSettingId: string) => {
    del(
      `/temp-setting/${tempSettingId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempSettings();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editTempSetting = (tempSettingId: string, tempSettingName: string) => {
    patch(
      `/temp-setting/${tempSettingId}`,
      { alias: tempSettingName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getTempSettings();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getTempSettings = useCallback(
    () => {
      get(
        '/temp-setting',
        { isDischarge: false },
        (res) => {
          if (isMounted) {
            setTempSetting(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getTempSettings();

    return () => {
      isMounted.current = false;
    }
  }, [getTempSettings])

  return (
    <>
      <EntityBox
        entityName='temp setting'
        header='Temp Settings'
        isLoading={isLoading}
        entities={tempSettings}
        isBroken={isBroken}
        delete={deleteTempSetting}
        edit={editTempSetting}
        createNewEntity={createNewTempSetting}
      />
    </>

  );
};

export default TempSettings;
import React, { } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GrowOnScroll from '../UI/GrowOnScroll/GrowOnScroll';
import AdminMenu from './AdminMenu/AdminMenu';
import AdminLoads from './Loads/AdminLoads';
import Users from './Users/Users';


const Admin: React.FC = () => {
  return (
    <div>
      <GrowOnScroll>
        <AdminMenu />
      </GrowOnScroll>

      <Routes>
        <Route
          path='/loads'
          element={<AdminLoads />}
        />

        <Route
          path='/users'
          element={<Users />}
        />

        <Route
          path='/'
          element={<Navigate to="loads" />}
        />
      </Routes>
    </div>
  );
};

export default Admin;

import User from '../../models/user';
import {
  SET_USER
} from '../constants/auth';


type AuthState = {
  user: User | undefined;
};

type AuthAction = {
  type: typeof SET_USER
  user?: User;
};

const initiateState: AuthState = {
  user: undefined
};

const auth = (state: AuthState = initiateState, action: AuthAction): AuthState => {
  if (action.type === SET_USER) {
    return {
      ...state,
      user: action.user
    };
  }

  return state;
};

export default auth;

import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { Button as StrapButton } from 'reactstrap';
import classes from './Button.module.scss';

interface ButtonProps {
  active?: boolean;
  buttonStyle: string;
  click?: MouseEventHandler<HTMLButtonElement>;
  type: 'button' | 'submit' | 'reset';
  text: string | ReactNode;
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <div className={classes.outer}>
      <StrapButton
        className={
          props.active
            ? classes[props.buttonStyle + '-active']
            : classes[props.buttonStyle]
        }
        onClick={props.click || (() => {})}
        type={props.type}
      >
        {props.text}
      </StrapButton>
    </div>
  );
};

export default Button;

import React, { useState } from 'react';
import classes from './Charges.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { Charge } from '../../../models/load';
import { FaTrash } from 'react-icons/fa';

interface ChargesProps {
  loadId: string
  charges: string
  submitCharges: (charges: Charge[], loadId: string) => void
};

const Charges: React.FC<ChargesProps> = (props) => {
  const [newCharge, setNewCharge] = useState<Charge>({ description: '', quantity: 1, amount: '' })
  const [charges, setCharges] = useState<Charge[]>(JSON.parse(props.charges))

  const removeCharge = (index: number) => {
    setCharges(charges.filter((charge, i) => {
      return index !== i
    }))
  }

  const addCharge = () => {
    if (newCharge.description === '' || newCharge.amount === '') {
      return
    }

    setNewCharge({ description: '', quantity: 1, amount: '' })
    setCharges(prevState => {
      const newCharges = [...prevState]
      const newAmount = parseFloat(newCharge.amount.split(' ')[1])

      newCharges.push({ ...newCharge, amount: newAmount.toFixed(2) })

      return newCharges
    })
  }

  const renderCharges = () => {
    return charges.map((charge, index) => (
      <div className={classes.charge} key={index}>
        <div>{charge.description}</div>
        <div className='d-flex'>
          <div className='px-3'>{charge.quantity} x R {charge.amount}</div>
          <div onClick={() => removeCharge(index)} className={classes.remove}><FaTrash /></div>
        </div>
      </div>
    ))
  }

  const updateNewCharge = (key: 'description' | 'quantity' | 'amount', value: string) => {
    setNewCharge(prevState => {
      const newState: Charge = { ...prevState }

      if (key === 'description') {
        newState.description = value
      } else if (key === 'quantity') {
        const intValue = parseInt(value)
        newState.quantity = intValue >= 1 ? intValue : 1
      } else if (key === 'amount') {
        newState.amount = value
      }

      return newState
    })
  }

  return (
    <div>
      <h6>Additional Charges</h6>

      <div className={classes.charges}>
        {renderCharges()}
      </div>

      <div className={classes['new-charge']}>
        <Input
          elementType={'text'}
          elementConfig={{
            type: 'text',
            placeholder: 'Description',
          }}
          value={newCharge.description}
          change={(e) => updateNewCharge('description', e.target.value)}
          id='name-input'
          inputStyle='main'
          labelHidden
        />

        <div className={classes.quantity}>
          <Input
            elementType={'quantity'}
            elementConfig={{
              type: 'number',
              placeholder: 'Quantity',
            }}
            value={newCharge.quantity.toString()}
            change={(e) => updateNewCharge('quantity', e.target.value)}
            id='quantity-input'
            inputStyle='main'
            labelHidden
          />
        </div>

        <div className={classes.x}>
          x
        </div>

        <div className={classes.currency}>
          <Input
            elementType={'currency'}
            elementConfig={{
              placeholder: 'R 0.00',
            }}
            value={newCharge.amount}
            change={(e) => updateNewCharge('amount', e.target.value)}
            id='amount-input'
            inputStyle='main'
            labelHidden
          />
        </div>

        <div className='px-2'></div>

        <div className='d-flex align-items-center'>
          <Button
            type='button'
            buttonStyle='main'
            text='Add'
            click={addCharge}
          />
        </div>
      </div>

      <div className='d-flex justify-content-center pt-3'>
        <Button
          type='button'
          buttonStyle='main'
          text='Submit'
          click={() => {
            const newCharges = [...charges]
            if (newCharge.description !== '' && newCharge.amount !== '') {
              const newAmount = parseFloat(newCharge.amount.split(' ')[1])
              newCharges.push({ ...newCharge, amount: newAmount.toFixed(2) })
            }
            props.submitCharges(newCharges, props.loadId)
          }}
        />
      </div>
    </div>
  );
};

export default Charges;
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';
import Load from '../../../models/load';
import classes from './LoadPdf.module.scss';
import logo from '../../../assets/images/logo.png';
import { isMobile } from 'react-device-detect';

interface LoadPdfProps {
  load: Load;
  close?: () => void;
}

const styles = StyleSheet.create({
  page: {
    fontSize: '12px'
  },
  outer: {
    margin: '15px',
    border: '1px solid black'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    paddingBottom: '10px',
    marginBottom: '20px'
  },
  image: {
    height: 'auto',
    width: '280px',
    marginLeft: '30px',
    marginTop: '30px'
  },
  row: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row'
  },
  halfCell: {
    width: '25%',
    padding: '8px',
    textAlign: 'center'
  },
  cell: {
    width: '50%',
    padding: '8px',
    textAlign: 'center'
  },
  blockCell: {
    width: '100%',
    padding: '8px'
  },
  endBorder: {
    borderRight: '1px solid black',
  },
  heading: {
    textDecoration: 'underline'
  },
  textRow: {
    padding: '8px',
    textAlign: 'center',
    width: '100%'
  },
  label: {

  },
  value: {
    color: 'rgb(100,100,100)'
  }
});

export const MyDocument = (props: LoadPdfProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.outer}>
        <View style={styles.header}>
          <Image src={logo} style={styles.image} />
        </View>

        {/* Row 1 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Loading Point 1:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.loadPoint1}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Booking Ref:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.bookingRef}</Text>
          </View>
        </View>

        {/* Row 2 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Loading Point 2:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.loadPoint2}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Exporter Ref:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.exporterRef}</Text>
          </View>
        </View>

        {/* Row 3 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Empty Depot:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.emptyDepot}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Client Ref:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.clientRef}</Text>
          </View>
        </View>

        {/* Row 4 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Shipping Line:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.shippingLine}</Text>
          </View>

          <View style={{ ...styles.halfCell }}>
            <Text></Text>
          </View>

          <View style={styles.halfCell}>
            <Text></Text>
          </View>
        </View>

        {/* Headings */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.heading}>Vessel Information:</Text>
          </View>

          <View style={{ ...styles.cell }}>
            <Text style={styles.heading}>Driver Details:</Text>
          </View>
        </View>

        {/* Row 5 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Vessel:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.vessel}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Name:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.driver?.alias}</Text>
          </View>
        </View>

        {/* Row 6 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Voyage:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.voyage}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Truck Reg:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.truckReg}</Text>
          </View>
        </View>


        {/* Row 7 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Loading Port:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.port}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Temptale:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.temptaleType}</Text>
          </View>
        </View>

        {/* Row 8 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Port of Discharge:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.dischargePort}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Supplier:</Text>
          </View>

          <View style={{ ...styles.halfCell }}>
            <Text style={styles.value}>{props.load.temptaleSupplier}</Text>
          </View>
        </View>

        {/* Headings */}
        <View style={styles.row}>
          <View style={{ ...styles.textRow }}>
            <Text style={styles.heading}>Container Information:</Text>
          </View>
        </View>

        {/* Row 9 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Container Info:</Text>
          </View>

          <View style={{ ...styles.cell }}>
            <Text style={styles.value}>{props.load.container}</Text>
          </View>
        </View>

        {/* Row 10 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Container Number:</Text>
          </View>

          <View style={{ ...styles.cell }}>
            <Text style={styles.value}>{props.load.containerNumber}</Text>
          </View>
        </View>

        {/* Row 11 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Seal Number:</Text>
          </View>

          <View style={{ ...styles.cell }}>
            <Text style={styles.value}>{props.load.sealNumber}</Text>
          </View>
        </View>

        {/* Row 12 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Commodity:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.commodity}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Vents:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.ventType}</Text>
          </View>
        </View>

        {/* Row 13 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Temp Code:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.tempCode}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Temp Sett:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.tempSetting}</Text>
          </View>
        </View>

        {/* Row 14 */}
        <View style={styles.row}>
          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Weigh:</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.value}>{props.load.weighMethod}</Text>
          </View>

          <View style={{ ...styles.halfCell, ...styles.endBorder }}>
            <Text style={styles.label}>Genset:</Text>
          </View>

          <View style={styles.halfCell}>
            <Text style={styles.value}>{props.load.isGenset ? 'Yes' : 'No'}</Text>
          </View>
        </View>

        {/* Row 15 */}
        <View style={styles.row}>
          <View style={{ ...styles.cell, ...styles.endBorder }}>
            <Text style={styles.label}>Load Date:</Text>
          </View>

          <View style={{ ...styles.cell }}>
            <Text style={styles.value}>{props.load.loadDate ? new Date(props.load.loadDate).toDateString() : ''}</Text>
          </View>
        </View>

        {/* Row 16 */}
        <View style={styles.row}>
          <View style={{ ...styles.blockCell, ...styles.endBorder }}>
            <Text style={styles.label}>Additional info:</Text>
            <Text style={styles.value}>{props.load.additionalInfo}</Text>
          </View>
        </View>

        {/* Row 17 */}
        <View style={styles.row}>
          <View style={styles.textRow}>
            <Text style={styles.label}>Danika: 071 687 7907 | danika@capecool.co.za</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document >
);

const LoadPdf = (props: LoadPdfProps) => (
  <div className={isMobile ? classes['pdf-mobile-container'] : classes['pdf-container']}>
    {isMobile ? (
      <div className='p-2 text-center'>CTO.{props.load.clientRef}.pdf</div>
    ) : (
      <div className={classes['pdf']}>
        <PDFViewer width="100%" height="100%" showToolbar={false}>
          <MyDocument load={props.load} />
        </PDFViewer>
      </div>
    )}

    <div className={classes['buttons']}>
      <div className={classes.cancel} onClick={props.close}>Close</div>

      <div className={classes['download']}>
        <PDFDownloadLink document={<MyDocument load={props.load} />} fileName={`CTO.${props.load.id}.pdf`}>
          {({ blob, url, loading, error }) => (loading ? '' : 'Download')}
        </PDFDownloadLink>
      </div>
    </div>
  </div>
);

export default LoadPdf;

import React, { FC } from 'react';
import Day from '../Day/Day';
import classes from './Days.module.scss';

interface DaysProps {
  weekdayOne: number | null;
  daysCount: number;
  setDay: (day: number) => void;
  date: Date;
  isValid: boolean;
}

const Days: FC<DaysProps> = (props) => {
  const renderDays = (days: number): JSX.Element[] | string => {
    let dayComps: JSX.Element[] = [];
    let blanks = 0;

    if (props.weekdayOne === null) {
      return '';
    }

    for (let i = 1; i <= days + blanks; i++) {
      if (i <= props.weekdayOne) {
        blanks = blanks + 1;
        dayComps.push(<Day key={i} isDummy={true} setDay={props.setDay} date={props.date} isValid={props.isValid} />);
      } else {
        dayComps.push(
          <Day
            key={i}
            day={i - blanks}
            setDay={props.setDay}
            date={props.date}
            isValid={props.isValid}
          />
        );
      }
    }

    return dayComps;
  };

  return (
    <div className={classes.days}>
      <div className={classes['weekdays']}>
        <span>Sun</span>
        <span>Mon</span>
        <span>Tue</span>
        <span>Wed</span>
        <span>Thu</span>
        <span>Fri</span>
        <span>Sat</span>
      </div>

      <div className={classes['days-inner']}>{renderDays(props.daysCount)}</div>
    </div>
  );
};

export default Days;

import React from 'react';
import classes from './Admin.module.scss';
import { Col, Container, Row } from 'reactstrap';
import Clients from './Clients/Clients';
import Drivers from './Drivers/Drivers';
import Depos from './Depos/Depos';
import TempTaleTypes from './TempTaleTypes/TempTaleTypes';
import LoadingPorts from './LoadingPorts/LoadingPorts';
import DischargePorts from './DischargePorts/DischargePorts';
import TempTaleSuppliers from './TempTaleSuppliers/TempTaleSuppliers';
import LoadPoints from './LoadPoints/LoadPoints';
import Vessels from './Vessels/Vessels';
import Commodities from './Commodities/Commodities';
import TempCodes from './TempCodes/TempCodes';
import ShippingLines from './ShippingLines/ShippingLines';
import WeighMethods from './WeighMethods/WeighMethods';
import Vents from './Vents/Vents';
import TempSettings from './TempSettings/TempSettings';
import Containers from './Containers/Containers';
import Statuses from './Statuses/Statuses';
import PluginDepots from './PluginDepots/PluginDepots';
import Accounts from './Accounts/Accounts';

// TODO: Handle errors in loading entities

const AdminLoads: React.FC = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md='6' xs='12'>
            <Clients />
          </Col>

          <Col md='6' xs='12'>
            <Commodities />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <Containers />
          </Col>
          <Col md='6' xs='12'>
            <Drivers />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <Depos />
          </Col>
          <Col md='6' xs='12'>
            <LoadPoints />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <LoadingPorts />
          </Col>
          <Col md='6' xs='12'>
            <DischargePorts />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <ShippingLines />
          </Col>
          <Col md='6' xs='12'>
            <TempCodes />
          </Col>


        </Row>

        <Row>
          <Col md='6' xs='12'>
            <TempSettings />
          </Col>
          <Col md='6' xs='12'>
            <TempTaleSuppliers />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <TempTaleTypes />
          </Col>
          <Col md='6' xs='12'>
            <Vents />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <Vessels />
          </Col>
          <Col md='6' xs='12'>
            <WeighMethods />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <Statuses />
          </Col>
          <Col md='6' xs='12'>
            <PluginDepots />
          </Col>
        </Row>

        <Row>
          <Col md='6' xs='12'>
            <Accounts />
          </Col>
          <Col md='6' xs='12'>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminLoads;
import React, { FC, CSSProperties, ReactNode, MouseEventHandler, useEffect } from 'react';
import classes from './Modal.module.scss';
import { Container, Row, Col } from 'reactstrap';

interface ModalProps {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
  zIndex?: number;
  close?: MouseEventHandler<HTMLDivElement>;
  stretch?: boolean;
  stacked?: boolean;
}

const Modal: FC<ModalProps> = (props) => {
  let modalClasses = `${classes.modal}`;
  let style = props.style || {};

  if (props.stretch === true) {
    style['width'] = '100%';
  }

  if (props.className) {
    modalClasses += ` ${props.className}`;
  }

  useEffect(() => {
    const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

    const originalPaddingRight = window.getComputedStyle(document.body).paddingRight;
    const scrollbarWidth = getScrollbarWidth();

    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = originalPaddingRight;
    };
  }, []);

  return (
    <div className={classes.parent} style={{zIndex: props.zIndex || 1000}}>
      <div className={props.stacked ? classes['stacked-backdrop'] : classes.backdrop} onClick={props.close}></div>

      <Container>
        <Row>
          <Col>
            <div className={modalClasses} style={style}>
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Modal;

import { AxiosResponse, AxiosRequestConfig } from 'axios';
import instance from './instance';
import { loadFromLocalStorage } from '../../helpers/loadFromLocalStorage';

const post = (
  path: string,
  params?: Record<string, any>,
  successCallback?: (response: AxiosResponse<any>) => void,
  errorCallback?: (error: string) => void,
  options?: AxiosRequestConfig
): void => {
  instance.defaults.withCredentials = true;

  const token = loadFromLocalStorage('token')
  const processedOptions = { ...options, headers: { Authorization: `Bearer ${token}`} };

  instance
    .post(path, params, processedOptions)
    .then((response) => {
      if (successCallback) successCallback(response);
    })
    .catch((error) => {
      let errorMessage = error.message;

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      if (errorCallback) {
        errorCallback(errorMessage);
      }
    });
};

export default post;

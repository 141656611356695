import React, { useCallback, useEffect, useRef, useState } from 'react';
import Load from '../../../models/load';
import get from '../../../services/axios/get';
import classes from './LoadCounts.module.scss'
import Loader from '../../UI/Loader/Loader';

interface LoadCountsProps { };

interface LoadCountsType {
  [key: string]: number;
}

interface CombinedCounts {
  [key: string]: {
    assigned?: number;
    unassigned?: number;
    plugged?: number;
  };
}

const LoadCounts: React.FC<LoadCountsProps> = (props) => {
  const [assignedCounts, setAssignedCounts] = useState<LoadCountsType>({})
  const [unAssignedCounts, setUnAssignedCounts] = useState<LoadCountsType>({})
  const [pluggedCounts, setPluggedCounts] = useState<LoadCountsType>({})
  const [combinedCounts, setCombinedCounts] = useState<CombinedCounts>({})
  const [isLoadingAssigned, setIsLoadingAssigned] = useState(true);
  const [isLoadingUnAssigned, setIsLoadingUnAssigned] = useState(true);
  const [isLoadingPlugged, setIsLoadingPlugged] = useState(true);
  const isMounted = useRef(true);

  const getLoads = useCallback(
    (loadStatus: Load['loadStatus']) => {
      get(
        `/load?loadStatus=${loadStatus}`,
        {},
        (res) => {
          const loadCounts: LoadCountsType = {}

          if (isMounted.current) {
            res.data.forEach((load: Load) => {
              if (!(load.client in loadCounts)) {
                loadCounts[load.client] = 0
              }

              loadCounts[load.client] += 1
            })

            if (loadStatus === 'assigned') {
              setAssignedCounts(loadCounts)
              setIsLoadingAssigned(false)
            } else if (loadStatus === 'unassigned') {
              setUnAssignedCounts(loadCounts)
              setIsLoadingUnAssigned(false)
            } else if (loadStatus === 'plugged') {
              setPluggedCounts(loadCounts)
              setIsLoadingPlugged(false)
            }
          }
        },
        () => { }
      );
    },
    []
  );

  useEffect(() => {
    getLoads('assigned');
    getLoads('unassigned');
    getLoads('plugged');

    return () => {
      isMounted.current = false;
    }
  }, [
    getLoads
  ])

  useEffect(() => {
    if (!isLoadingAssigned && !isLoadingUnAssigned && !isLoadingPlugged) {
      const combined: CombinedCounts = {};

      Object.keys(assignedCounts).forEach(client => {
        if (!combined[client]) combined[client] = {};
        combined[client].assigned = assignedCounts[client];
      });

      Object.keys(unAssignedCounts).forEach(client => {
        if (!combined[client]) combined[client] = {};
        combined[client].unassigned = unAssignedCounts[client];
      });

      Object.keys(pluggedCounts).forEach(client => {
        if (!combined[client]) combined[client] = {};
        combined[client].plugged = pluggedCounts[client];
      });

      setCombinedCounts(combined);
    }
  }, [
    assignedCounts, unAssignedCounts, pluggedCounts,
    isLoadingAssigned, isLoadingPlugged, isLoadingUnAssigned
  ])

  return (
    <div className={classes.container}>
      <div className={classes.headers}>
        <div>Client</div>
        <div>Unassigned</div>
        <div>Assigned</div>
        <div>Plugged</div>
        <div>Total</div>
      </div>
      {(isLoadingAssigned || isLoadingPlugged || isLoadingUnAssigned) ? (<div className='p-5 m-auto'>
        <Loader
          size='large'
        />
      </div>) : Object.keys(combinedCounts).map((client: string, index: number) => {
        const shadeClass = index % 2 === 0 ? 'even' : 'odd';

        return (
          <div key={client} className={[classes.row, classes[shadeClass]].join(' ')}>
            <div>{client}</div>
            <div>{combinedCounts[client].unassigned || 0}</div>
            <div>{combinedCounts[client].assigned || 0}</div>
            <div>{combinedCounts[client].plugged || 0}</div>
            <div>{(combinedCounts[client].unassigned || 0) + (combinedCounts[client].assigned || 0) + (combinedCounts[client].plugged || 0)}</div>
          </div>
        )
      })}
    </div>
  );
};

export default LoadCounts;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Statuses.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface StatusProps { };

const Statuses: React.FC<StatusProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [shippingLines, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewStatus = (status: { alias: string }) => {
    post(
      '/status',
      { alias: status.alias, isDischarge: false },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getStatuses();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteStatus = (shippingLineId: string) => {
    del(
      `/status/${shippingLineId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getStatuses();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editStatus = (shippingLineId: string, shippingLineName: string) => {
    patch(
      `/status/${shippingLineId}`,
      { alias: shippingLineName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getStatuses();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getStatuses = useCallback(
    () => {
      get(
        '/status',
        { isDischarge: false },
        (res) => {
          if (isMounted) {
            setStatus(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getStatuses();

    return () => {
      isMounted.current = false;
    }
  }, [getStatuses])

  return (
    <>
      <EntityBox
        entityName='status'
        header='Statuses'
        isLoading={isLoading}
        entities={shippingLines}
        isBroken={isBroken}
        delete={deleteStatus}
        edit={editStatus}
        createNewEntity={createNewStatus}
      />
    </>

  );
};

export default Statuses;
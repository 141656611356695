import React from 'react';
import classes from './LoadsMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useAppSelector } from '../../../store/hooks';
import { isMobile } from 'react-device-detect';

const LoadsMenu: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)

  return (
    <Container>
      <Row>
        <Col>
          <div className={[classes['loads-menu']].join(' ')}>
            {user?.userType === 'driver' ? (
              <>
                <NavLink to='new'
                  className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
                >New</NavLink>

                <NavLink to='active'
                  className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
                >Active</NavLink>
              </>
            ) : (
              <>
                <NavLink to='unassigned'
                  className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
                >Unassigned</NavLink>

                <NavLink to='assigned'
                  className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
                >Assigned</NavLink>

                <NavLink to='plugged'
                  className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
                >Plugged</NavLink>
              </>
            )}

            <NavLink to='completed'
              className={({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}
            >{isMobile ? 'Completed' : 'Completed Loads'}</NavLink>
          </div>
        </Col>
      </Row>
    </Container>

  );
};

export default LoadsMenu;

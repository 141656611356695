import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Drivers.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface DriverProps { };

const Drivers: React.FC<DriverProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [drivers, setDriver] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewDriver = (driver: { alias: string, phoneNumber?: string }) => {
    post(
      '/driver',
      { alias: driver.alias, phoneNumber: driver.phoneNumber },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDrivers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteDriver = (driverId: string) => {
    del(
      `/driver/${driverId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDrivers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editDriver = (driverId: string, driverName: string, phoneNumber?: string) => {
    patch(
      `/driver/${driverId}`,
      { alias: driverName, phoneNumber },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDrivers();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getDrivers = useCallback(
    () => {
      get(
        '/driver',
        {},
        (res) => {
          if (isMounted) {
            setDriver(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getDrivers();

    return () => {
      isMounted.current = false;
    }
  }, [getDrivers])

  return (
    <>
      <EntityBox
        entityName='driver'
        header='Drivers'
        isLoading={isLoading}
        entities={drivers}
        isBroken={isBroken}
        delete={deleteDriver}
        edit={editDriver}
        createNewEntity={createNewDriver}
      />
    </>

  );
};

export default Drivers;
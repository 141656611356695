import React, { useCallback, useEffect, useRef, useState } from 'react';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface DepoProps { };

const PluginDepots: React.FC<DepoProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [PluginDepots, setPluginDepots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewDepo = (depot: { alias: string }) => {
    post(
      '/depot',
      { alias: depot.alias, isPlugin: true },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getPluginDepots();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteDepo = (depoId: string) => {
    del(
      `/depot/${depoId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getPluginDepots();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editDepo = (depoId: string, depoName: string) => {
    patch(
      `/depot/${depoId}`,
      { alias: depoName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getPluginDepots();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getPluginDepots = useCallback(
    () => {
      get(
        '/depot',
        { isPlugin: true },
        (res) => {
          if (isMounted) {
            setPluginDepots(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getPluginDepots();

    return () => {
      isMounted.current = false;
    }
  }, [getPluginDepots])

  return (
    <>
      <EntityBox
        entityName='plugin depot'
        header='Plugin Depots'
        isLoading={isLoading}
        entities={PluginDepots}
        isBroken={isBroken}
        delete={deleteDepo}
        edit={editDepo}
        createNewEntity={createNewDepo}
      />
    </>

  );
};

export default PluginDepots;
import { FC, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import classes from './ActivateAccount.module.scss';
import { useAppDispatch } from '../../store/hooks';
import { customAlert } from '../../store/actions/alert';
import { activate } from '../../store/actions/auth'
import checkValidity from '../../helpers/checkValidity';
import Loader from '../UI/Loader/Loader';

function validatePassword(password: string): { message?: string, isValid: boolean } {
  const requirements = [
    { regex: /^(?=.*[a-z])/, message: 'Must contain at least one lowercase letter' },
    { regex: /^(?=.*[A-Z])/, message: 'Must contain at least one uppercase letter' },
    { regex: /^(?=.*[0-9])/, message: 'Must contain at least one number' },
    { regex: /^(?=.*[!@#$%^&*()_+=-{};:<>,./?])/, message: 'Must contain at least one special character' },
    { regex: /^.{8,}$/, message: 'Must be at least 8 characters long' },
  ];

  for (const requirement of requirements) {
    if (!requirement.regex.test(password)) {
      return { isValid: false, message: requirement.message };
    }
  }

  return { isValid: true };
}

const ActivateAccount: FC = () => {
  const dispatch = useAppDispatch();
  const [loginForm, setLoginForm] = useState({
    username: {
      value: '',
      touched: false,
      isValid: false
    },
    password: {
      value: '',
      touched: false,
      isValid: false
    },
    repeatPassword: {
      value: '',
      touched: false,
      isValid: false
    },
    activationCode: {
      value: '',
      touched: false,
      isValid: false
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const login = () => {
    const passwordValidation = validatePassword(loginForm.password.value)

    if (!loginForm.username.isValid) {
      dispatch(customAlert(true, 'Username is invalid'));
    } else if (!loginForm.password.isValid) {
      dispatch(customAlert(true, 'Password is invalid'));
    } else if (!loginForm.activationCode.isValid) {
      dispatch(customAlert(true, 'Activation code is invalid'));
    } else if (loginForm.password.value !== loginForm.repeatPassword.value) {
      dispatch(customAlert(true, 'Password repeat does not match'));
    } else if (!passwordValidation.isValid) {
      dispatch(customAlert(true, passwordValidation.message));
    } else {
      setIsLoading(true);
      dispatch(activate(
        loginForm.username.value,
        loginForm.password.value,
        loginForm.activationCode.value,
        (success, response) => {
          if (!success || response?.status !== 200) {
            setIsLoading(false);
          }
        }
      ));
    }
  };

  const updateLoginForm = (value: string, identifier: 'username' | 'password' | 'repeatPassword' | 'activationCode') => {
    if (value[value.length - 1] !== ' ') {
      let newLoginForm = { ...loginForm };
      newLoginForm[identifier].value = value;
      newLoginForm[identifier].touched = true;
      newLoginForm[identifier].isValid = checkValidity(value, {
        required: true,
        minLength: 3,
        maxLength: 40
      });
      setLoginForm(newLoginForm);
    }
  };

  return (
    <div className={classes.window}>
      <div className='title'>
        <span>Activate Account</span>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}
      >
        <div className={classes['input-box']}>
          <Input
            elementType={'input'}
            elementConfig={{
              type: 'text',
              placeholder: 'Username'
            }}
            value={loginForm.username.value}
            invalid={!loginForm.username.isValid}
            shouldValidate
            touched={loginForm.username.touched}
            change={(e) => updateLoginForm(e.target.value, 'username')}
            id='username-input'
            label='Username'
            labelHidden
            inputStyle='main'
          />

          <Input
            elementType={'input'}
            elementConfig={{
              type: 'text',
              placeholder: 'Activation Code'
            }}
            value={loginForm.activationCode.value}
            invalid={!loginForm.activationCode.isValid}
            shouldValidate
            touched={loginForm.activationCode.touched}
            change={(e) => updateLoginForm(e.target.value, 'activationCode')}
            id='activationCode-input'
            label='activationCode'
            labelHidden
            inputStyle='main'
          />

          <Input
            elementType={'input'}
            elementConfig={{
              type: 'password',
              placeholder: 'Password'
            }}
            value={loginForm.password.value}
            invalid={!loginForm.password.value}
            shouldValidate
            touched={loginForm.password.touched}
            change={(e) => updateLoginForm(e.target.value, 'password')}
            id='password-input'
            label='Password'
            labelHidden
            inputStyle='main'
          />

          <Input
            elementType={'input'}
            elementConfig={{
              type: 'password',
              placeholder: 'Repeat Password'
            }}
            value={loginForm.repeatPassword.value}
            invalid={!loginForm.repeatPassword.value}
            shouldValidate
            touched={loginForm.repeatPassword.touched}
            change={(e) => updateLoginForm(e.target.value, 'repeatPassword')}
            id='repeat-password-input'
            label='Password'
            labelHidden
            inputStyle='main'
          />

        </div>

        <div className={classes['button-box']}>
          {isLoading ? <div className={classes['loader-box']}>
            <Loader size='small' />
          </div> : <Button type='submit' buttonStyle='main' text='Activate' />}
        </div>
      </form>
    </div>
  );
};

export default ActivateAccount;

import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { logout } from '../../store/actions/auth';
import classes from './Header.module.scss';
import HeaderLeft from './HeaderLeft/HeaderLeft';
import HeaderRight from './HeaderRight/HeaderRight';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import BottomUpMenu from '../UI/BottomUpMenu/BottomUpMenu';
import { NavLink } from 'react-router-dom';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const user = useAppSelector(state => state.auth.user)


  const toggleShowMenu = () => {
    if (!showMenu) {
      window.scrollTo(0, 0);
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    setShowMenu((prevState) => !prevState);
  };

  return (
    <header className={classes.header}>
      <Container fluid>
        <Row>
          <Col>
            <HeaderLeft />
          </Col>

          <Col className='d-sm-block d-none'>
            {/* <HeaderMiddle /> */}
          </Col>

          <Col className='d-sm-block d-none'>
            <HeaderRight
              logout={() => dispatch(logout())}
            />
          </Col>

          <Col className={'d-sm-none d-block'}>
            <BottomUpMenu
              toggleShowMenu={toggleShowMenu}
              showMenu={showMenu}
            >

              {user?.userType !== 'driver' ? (
                <>
                  <div className={classes['link-box']} onClick={toggleShowMenu}>
                    <NavLink to='/home' className={
                      ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Home</NavLink>
                  </div>

                  <div className={classes['link-box']} onClick={toggleShowMenu}>
                    <NavLink to='/loads' className={
                      ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Loads</NavLink>
                  </div>
                </>
              ) : null}

              {user?.userType === 'admin' ? (
                <div className={classes['link-box']} onClick={toggleShowMenu}>
                  <NavLink to='/admin' className={
                    ({ isActive, isPending }) => isPending ? classes.pending : isActive ? classes.active : ""}>Admin</NavLink>
                </div>
              ) : null}

              <div className={classes.logout}>
                <span onClick={() => dispatch(logout())}>Logout</span>
              </div>
            </BottomUpMenu>
          </Col>
        </Row>
      </Container>
    </header>
  );

};

export default Header;

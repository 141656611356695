import React, { ReactNode } from 'react';
import classes from './BottomUpMenu.module.scss';
import { CgMenu } from 'react-icons/cg';

interface BottomUpMenuProps {
  showMenu: boolean;
  toggleShowMenu: () => void;
  children: ReactNode;
}

const BottomUpMenu: React.FC<BottomUpMenuProps> = (props) => {
  let appliedClasses = [classes['bottom-up-menu']];

  if (props.showMenu) {
    appliedClasses.push(classes.open);
  } else {
    appliedClasses.push(classes.closed);
  }

  return (
    <>
      <div className={classes['menu-icon-container']}>
        <CgMenu size={30} color='var(--theme-blue)' onClick={props.toggleShowMenu} />
      </div>

      <div className={appliedClasses.join(' ')}>
        {props.children}
      </div>
    </>


  );
};

export default BottomUpMenu;

import React from 'react';
import classes from './HeaderLeft.module.scss';
import logo from '../../../assets/images/logo.png';

const HeaderLeft: React.FC = () => {
  return (
    <div className={classes['header-left']}>
      <img src={logo} alt='Logo' />
    </div>
  );
};

export default HeaderLeft;

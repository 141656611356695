import React, { ReactNode } from 'react';
import classes from './LogoBackdrop.module.scss';
import logo from '../../assets/images/logo.png';

interface LogoBackdropProps {
  hideWindow?: boolean;
  children?: ReactNode;
}

const LogoBackdrop: React.FC<LogoBackdropProps> = (props) => {
  const windowClass = props.hideWindow ? 'window-hidden' : 'window';

  return (
    <div>
      <div className={classes.logo}>
        <img src={logo} alt='Logo' />
      </div>

      <div className={classes['window-outer']}>
        <div className={classes[windowClass]}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default LogoBackdrop;

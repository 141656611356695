import React, { } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GrowOnScroll from '../UI/GrowOnScroll/GrowOnScroll';
import LoadsMenu from './LoadsMenu/LoadsMenu';
import LoadsContainer from './LoadsContainer/LoadsContainer';
import { useAppSelector } from '../../store/hooks';
import DriverLoads from '../Driver/DriverLoads/DriverLoads';


const Loads: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)

  return (
    <div>
      <GrowOnScroll>
        <LoadsMenu />
      </GrowOnScroll>

      <Routes>
        <Route
          path='/unassigned'
          element={<LoadsContainer loadStatus='unassigned' />}
        />

        <Route
          path='/assigned'
          element={<LoadsContainer loadStatus='assigned' />}
        />

        <Route
          path='/plugged'
          element={<LoadsContainer loadStatus='plugged' />}
        />

        <Route
          path='/completed'
          element={user?.userType === 'driver' ?
            <DriverLoads key='completed-loads' loadStatus='completed' /> :
            <LoadsContainer loadStatus='completed' />}
        />

        <Route
          path='/active'
          element={<DriverLoads key='active-loads' loadStatus='active' />}
        />

        <Route
          path='/new'
          element={<DriverLoads key='new-loads' loadStatus='new' />}
        />

        {user?.userType === 'driver' ? (
          <Route
            path='/'
            element={<Navigate to="active" />}
          />
        ) : (
          <Route
            path='/'
            element={<Navigate to="unassigned" />}
          />
        )}
      </Routes>
    </div>
  );
};

export default Loads;

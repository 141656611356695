import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './GrowOnScroll.module.scss';

interface GrowOnScrollProps {
  children: React.ReactNode
}

const GrowOnScroll: FC<GrowOnScrollProps> = (props) => {
  const maxPadding = 15;
  const minPadding = 2;
  const [isSticky, setSticky] = useState(false);
  const [padding, setPadding] = useState('20px');
  const [spacerPadding, setSpacerPadding] = useState('0px');
  const ref: any = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const divTop = ref.current.getBoundingClientRect().top + 90;
      setSticky(window.scrollY > divTop);

      if (window.scrollY > divTop) {
        const scrollDistance = window.scrollY - divTop;
        const paddingValue = Math.max(minPadding, maxPadding - (scrollDistance * 10 / 50));
        // TODO: This value needs to be dynamic
        setSpacerPadding('26.5px')
        setPadding(`${paddingValue}px`);
      } else {
        setSpacerPadding('0px')
        setPadding(`${maxPadding}px`);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <div style={{padding: spacerPadding}}></div>

    <div
      ref={ref}
      className={[isSticky ? classes.sticky : '', classes['grow-on-scroll']].join(' ')}
      style={{ paddingTop: padding, paddingBottom: padding }}
    >
      {props.children}
    </div>
    </>

  );
};

export default GrowOnScroll;

import React, { ReactNode, FC } from 'react';

interface AxlProps {
  children: ReactNode;
}

const Axl: FC<AxlProps> = (props) => {
  return <>{props.children}</>;
};

export default Axl;

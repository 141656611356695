import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Vessel.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface VesselsProps { };

const Vessels: React.FC<VesselsProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [vessels, setVessel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewVessel = (vessel: { alias: string }) => {
    post(
      '/vessel',
      { alias: vessel.alias },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVessels();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteVessel = (vesselId: string) => {
    del(
      `/vessel/${vesselId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVessels();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editVessel = (vesselId: string, vesselName: string) => {
    patch(
      `/vessel/${vesselId}`,
      { alias: vesselName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getVessels();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getVessels = useCallback(
    () => {
      get(
        '/vessel',
        {},
        (res) => {
          if (isMounted) {
            setVessel(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getVessels();

    return () => {
      isMounted.current = false;
    }
  }, [getVessels])

  return (
    <>
      <EntityBox
        entityName='vessel'
        header='Vessels'
        isLoading={isLoading}
        entities={vessels}
        isBroken={isBroken}
        delete={deleteVessel}
        edit={editVessel}
        createNewEntity={createNewVessel}
      />
    </>

  );
};

export default Vessels;
import React, { FC, MouseEvent } from 'react';
import classes from './Months.module.scss';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

interface MonthsProps {
  month: number;
  year: number;
  setMonth: (month: number) => void;
  setYear: (year: number, month: number) => void;
}

const Months: FC<MonthsProps> = (props) => {
  const right = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (props.month < 11) {
      props.setMonth(props.month + 1);
    } else {
      props.setYear(props.year + 1, 0);
    }
  };

  const left = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (props.month > 0) {
      props.setMonth(props.month - 1);
    } else {
      props.setYear(props.year - 1, 11);
    }
  };

  return (
    <div className={classes['months']}>
      <span className={classes['arrow']} onClick={left}>
        <FaChevronLeft color='var(--off-black)' />
      </span>
      <span className={classes['display-value']}>{months[props.month]} {props.year}</span>
      <span className={classes['arrow']} onClick={right}>
        <FaChevronRight color='var(--off-black)' />
      </span>
    </div>
  );
};

export default Months;

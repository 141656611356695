import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './ports.module.scss';
import EntityBox from '../EntityBox/EntityBox';
import get from '../../../../services/axios/get';
import del from '../../../../services/axios/delete';
import patch from '../../../../services/axios/patch';
import { useAppDispatch } from '../../../../store/hooks';
import { customAlert } from '../../../../store/actions/alert';
import post from '../../../../services/axios/post';

interface DischargePortProps { };

const DischargePorts: React.FC<DischargePortProps> = (props) => {
  const dispatch = useAppDispatch()
  const isMounted = useRef(true);
  const [dischargePorts, setDischargePort] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBroken, setIsBroken] = useState(false);

  const createNewDischargePort = (dischargePort: { alias: string }) => {
    post(
      '/port',
      { alias: dischargePort.alias, isDischarge: true },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDischargePorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  }

  const deleteDischargePort = (dischargePortId: string) => {
    del(
      `/port/${dischargePortId}`,
      {},
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDischargePorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const editDischargePort = (dischargePortId: string, dischargePortName: string) => {
    patch(
      `/port/${dischargePortId}`,
      { alias: dischargePortName },
      () => {
        if (isMounted) {
          setIsLoading(true);
          getDischargePorts();
        }
      },
      (err) => {
        if (isMounted) {
          dispatch(customAlert(true, err));
          setIsLoading(false);
          setIsBroken(true);
        }
      }
    )
  };

  const getDischargePorts = useCallback(
    () => {
      get(
        '/port',
        { isDischarge: true },
        (res) => {
          if (isMounted) {
            setDischargePort(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsBroken(true);
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getDischargePorts();

    return () => {
      isMounted.current = false;
    }
  }, [getDischargePorts])

  return (
    <>
      <EntityBox
        entityName='port of discharge'
        header='Ports of Discharge'
        isLoading={isLoading}
        entities={dischargePorts}
        isBroken={isBroken}
        delete={deleteDischargePort}
        edit={editDischargePort}
        createNewEntity={createNewDischargePort}
      />
    </>

  );
};

export default DischargePorts;
import checkIsLeap from './checkIsLeap';

const getMonthDays = (year: number, month: number): number => {
  let monthDays: number | null = null;

  switch (month) {
    case 0:
      monthDays = 31;
      break;
    case 1:
      monthDays = checkIsLeap(year) ? 29 : 28;
      break;
    case 2:
      monthDays = 31;
      break;
    case 3:
      monthDays = 30;
      break;
    case 4:
      monthDays = 31;
      break;
    case 5:
      monthDays = 30;
      break;
    case 6:
      monthDays = 31;
      break;
    case 7:
      monthDays = 31;
      break;
    case 8:
      monthDays = 30;
      break;
    case 9:
      monthDays = 31;
      break;
    case 10:
      monthDays = 30;
      break;
    case 11:
      monthDays = 31;
      break;
    default:
      throw new Error('Invalid month provided');
  }

  return monthDays;
};

export default getMonthDays;

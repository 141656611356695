import React from 'react';
import Load from '../../../../models/load';
import Status from '../../../../models/status';
import classes from './LoadItem.module.scss';
import { Col, Row } from 'reactstrap';

interface LoadItemProps {
  statuses: {
    statuses: Status[]
    isLoading: boolean
  }
  refresh: () => void
  key: string
  shade: 'even' | 'odd'
  load: Load
  selected: boolean
  select: (load: Load) => void
};

const LoadItem: React.FC<LoadItemProps> = (props) => {
  return (
    <>
      <Row
        onClick={() => props.select(props.load)}
        className={[
          classes[props.shade],
          'd-flex',
          classes['load-item'],
          'g-0',
          'py-2',
          props.selected ? classes['selected'] : ''
        ].join(' ')}>
        <Col className={[classes['equal-width-columns']].join(' ')}>
          <div>{
            props.load.loadDate !== '' &&
              props.load.loadDate != null ?
              new Date(props.load.loadDate).toDateString() : null
          }
          </div>
        </Col>

        <Col className={[classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
          <div>{props.load.loadPoint1}</div>
        </Col>

        <Col className={[classes['hide-tablet'], classes['equal-width-columns']].join(' ')}>
          <div>{props.load.loadPoint2}</div>
        </Col>
      </Row>
    </>
  );
};

export default LoadItem;